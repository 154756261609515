import React from "react"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import NoSsr from "@mui/material/NoSsr"
import { Button, useMediaQuery, useTheme } from "@mui/material"
import { styled, alpha } from "@mui/system"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"

import Container from "../../../../components/Container"
import { scrollTo } from "../../../../helpers"

const Video = styled("video")({
  objectFit: "cover",
  height: "100%",
  width: "100%",
  position: "absolute",
  zIndex: 3
})

const Hero = () => {
  const theme = useTheme()

  const {
    mixins: { toolbar },
    breakpoints
  } = theme

  const queryDesktop = breakpoints.up("sm")
  const queryLandscape = `${breakpoints.up("xs")} and (orientation: landscape)`

  const isDesktop = useMediaQuery(queryDesktop)
  const isLandscape = useMediaQuery(queryLandscape)

  // eslint-disable-next-line no-nested-ternary
  const cssToolbar = toolbar[isDesktop ? queryDesktop : isLandscape ? queryLandscape : ""]

  const height = (cssToolbar ?? toolbar)?.minHeight ?? 0

  return (
    <Box
      minHeight="100vh"
      display="flex"
      alignItems="center"
      bgcolor="alternate.main"
      marginTop={`-${height}px`}
      paddingTop={`${height}px`}
    >
      <Container>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          zIndex={2}
        >
          <Video loop autoPlay muted disablePictureInPicture disableRemotePlayback playsInline>
            <source src="./assets/videos/bg.mp4" type="video/mp4" />
            Your browser does not support HTML5 video.
          </Video>
          <Box
            width="100%"
            height="100%"
            position="absolute"
            top={0}
            left={0}
            zIndex={4}
            sx={{
              bgcolor: alpha(theme.palette.alternate.main, 0.9)
            }}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          position="relative"
          top={0}
          left={0}
          zIndex={5}
        >
          <Box>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Box width="100%" height="100%" display="flex" justifyContent="center">
                  <Box>
                    <Typography
                      variant="h3"
                      align="center"
                      gutterBottom
                      sx={{
                        fontWeight: 900
                      }}
                    >
                      We craft beautiful software.
                    </Typography>
                    <Typography
                      variant="h6"
                      component="p"
                      color="text.secondary"
                      align="center"
                      sx={{
                        fontWeight: 400
                      }}
                    >
                      Tell us your project requirements, budget and timeline, and we will provide
                      you with the most outstanding service.
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box marginTop={4}>
            <NoSsr>
              <Button
                variant="outlined"
                endIcon={<ChevronRightIcon />}
                onClick={() => scrollTo("about")}
              >
                Learn more
              </Button>
            </NoSsr>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default Hero
