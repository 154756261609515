import React from "react"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import Avatar from "@mui/material/Avatar"
import ListItem from "@mui/material/ListItem"
import ListItemAvatar from "@mui/material/ListItemAvatar"
import ListItemText from "@mui/material/ListItemText"
import CheckIcon from "@mui/icons-material/Check"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme } from "@mui/material/styles"

const logos = [
  {
    logo: "./assets/images/web/react.png",
    name: "React Native"
  },
  {
    logo: "./assets/images/mobile/ionic.png",
    name: "Ionic"
  },
  {
    logo: "./assets/images/mobile/nativescript.png",
    name: "NativeScript"
  },
  {
    logo: "./assets/images/mobile/cordova.png",
    name: "Cordova"
  }
]

const Mobile = () => {
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true
  })

  return (
    <Box>
      <Grid container flexDirection={{ xs: "column-reverse", md: "row" }}>
        <Grid item xs={12} md={6}>
          <Box data-aos="fade-in" data-aos-delay={isMd ? 0 : logos.length * 100}>
            <Box marginBottom={2}>
              <Typography variant="h4" color="common.white" sx={{ fontWeight: 700 }}>
                Mobile applications.
              </Typography>
            </Box>
            <Box marginBottom={3}>
              <Typography variant="h6" component="p" color="common.white">
                We are experts in developing Web technologies based cross-platform Mobile
                applications and preserving code reusability.
              </Typography>
            </Box>
            <Grid container spacing={1}>
              {logos.map((item, i) =>
                !item.ignoreFromList ? (
                  <Grid item xs={12} key={i}>
                    <Box
                      component={ListItem}
                      disableGutters
                      width="auto"
                      padding={0}
                      color="common.white"
                    >
                      <Box component={ListItemAvatar} minWidth="auto !important" marginRight={2}>
                        <Box
                          component={Avatar}
                          bgcolor="common.white"
                          width={20}
                          height={20}
                          color="#1e2022"
                        >
                          <CheckIcon fontSize="small" />
                        </Box>
                      </Box>
                      <ListItemText primary={item.description || item.name} />
                    </Box>
                  </Grid>
                ) : null
              )}
            </Grid>
          </Box>
        </Grid>
        <Grid
          item
          container
          xs={12}
          md={6}
          sx={{
            justifyContent: { xs: "flex-start", md: "flex-end" },
            marginBottom: { xs: 2, md: 0 },
            alignItems: "center",
            transform: { xs: "none", md: "translateY(-40px)" }
          }}
        >
          {logos.map((item, i) => (
            <Box
              key={i}
              data-aos="fade-in"
              data-aos-delay={isMd ? i * 100 + 100 : i * 100}
              data-aos-offset={100}
            >
              <Avatar
                src={item.logo}
                alt={item.name}
                sx={{
                  bgcolor: "background.paper",
                  width: { xs: 40, md: 100 },
                  height: { xs: 40, md: 100 },
                  padding: { xs: 1, md: 2 },
                  boxShadow: 4,
                  margin: { xs: 0.5, md: 1 },
                  transform: i % 2 === 1 ? { md: "translateY(80px)" } : "none",
                  "> img": {
                    objectFit: "contain",
                    filter:
                      theme.palette.mode === "dark"
                        ? "brightness(0) invert(1)"
                        : "invert(12%) sepia(8%) saturate(4364%) hue-rotate(187deg) brightness(95%) contrast(86%)"
                  }
                }}
              />
            </Box>
          ))}
        </Grid>
      </Grid>
    </Box>
  )
}

export default Mobile
