import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useLocation, useNavigate } from "react-router-dom"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import AppBar from "@mui/material/AppBar"
import useScrollTrigger from "@mui/material/useScrollTrigger"
import Fab from "@mui/material/Fab"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import Zoom from "@mui/material/Zoom"
import NoSsr from "@mui/material/NoSsr"

import Container from "../../components/Container"
import { scrollTo } from "../../helpers"

import { Topbar, Sidebar, Footer } from "./components"

const Main = ({ children, shouldColourInvert = false, bgcolor = "transparent" }) => {
  const { state, pathname } = useLocation()
  const navigate = useNavigate()

  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true
  })

  const [openSidebar, setOpenSidebar] = useState(false)

  const handleSidebarOpen = () => {
    setOpenSidebar(true)
  }

  const handleSidebarClose = () => {
    setOpenSidebar(false)
  }

  const open = isMd ? false : openSidebar

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 38
  })

  useEffect(() => {
    if (state?.scrollTo)
      scrollTo(
        state.scrollTo.includes("#") ? state.scrollTo.replace("#", "") : state.scrollTo,
        state?.behavior,
        () => navigate(pathname, { state: {}, preventScrollReset: true })
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  return (
    <Box id="top">
      <AppBar
        position="sticky"
        sx={{
          top: 0,
          backgroundColor: trigger ? theme.palette.background.paper : bgcolor
        }}
        elevation={trigger ? 1 : 0}
      >
        <Container paddingY={1}>
          <Topbar
            onSidebarOpen={handleSidebarOpen}
            pages={{}}
            shouldColourInvert={trigger ? false : shouldColourInvert}
          />
        </Container>
      </AppBar>
      <Sidebar onClose={handleSidebarClose} isOpen={open} variant="temporary" pages={{}} />
      <main>
        {children}
        <Divider />
      </main>
      <Container paddingY={4}>
        <Footer />
      </Container>
      <NoSsr>
        <Zoom in={trigger}>
          <Box
            onClick={() => scrollTo("top")}
            role="presentation"
            sx={{ position: "fixed", bottom: 24, right: 32 }}
          >
            <Fab color="primary" size="small" aria-label="scroll back to top">
              <KeyboardArrowUpIcon />
            </Fab>
          </Box>
        </Zoom>
      </NoSsr>
    </Box>
  )
}

Main.propTypes = {
  children: PropTypes.node,
  shouldColourInvert: PropTypes.bool,
  bgcolor: PropTypes.string
}

export default Main
