import React from "react"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import PhoneIcon from "@mui/icons-material/Phone"
import EmailIcon from "@mui/icons-material/Email"
import LinkedInIcon from "@mui/icons-material/LinkedIn"
import FacebookIcon from "@mui/icons-material/Facebook"
import InstagramIcon from "@mui/icons-material/Instagram"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"

import { Form } from "./components"

const Contact = () => {
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true
  })

  const buttonSx = {
    minWidth: { xs: "unset", md: "64px" },
    ".MuiButton-startIcon": {
      mr: { xs: 0, md: 1 },
      ml: { xs: 0, md: -0.5 }
    }
  }

  return (
    <Box>
      <Box mb={{ xs: 2, md: 4 }}>
        <Typography
          sx={{
            textTransform: "uppercase",
            fontWeight: "medium"
          }}
          gutterBottom
          color="primary"
          align="left"
        >
          Contact us
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 700
          }}
        >
          Get in touch.
        </Typography>
        <Typography variant="h6" color="text.secondary">
          We'd love to talk about how we can help you. <br />
          <br />
          Find us at the following links.
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" mb={{ xs: 2, md: 4 }}>
        <Box marginRight={{ xs: 0, md: 2 }}>
          <Button
            variant="text"
            size="large"
            component="a"
            target="_blank"
            href="tel:+447715344056"
            startIcon={<PhoneIcon />}
            sx={buttonSx}
          >
            {isMd ? "+44 (0) 7715 344 056" : null}
          </Button>
        </Box>
        <Box marginRight={{ xs: 0, md: 2 }}>
          <Button
            variant="text"
            size="large"
            component="a"
            target="_blank"
            href="mailto:contact@apogeom.com"
            startIcon={<EmailIcon />}
            sx={buttonSx}
          >
            {isMd ? "contact@apogeom.com" : null}
          </Button>
        </Box>
        <Box marginRight={{ xs: 0, md: 2 }}>
          <Button
            variant="text"
            size="large"
            component="a"
            target="blank"
            href="https://uk.linkedin.com/company/apogeom"
            startIcon={<LinkedInIcon />}
            sx={buttonSx}
          >
            {isMd ? "Linkedin" : null}
          </Button>
        </Box>
        <Box marginRight={{ xs: 0, md: 2 }}>
          <Button
            variant="text"
            size="large"
            component="a"
            target="blank"
            href="https://facebook.com/apogeom"
            startIcon={<FacebookIcon />}
            sx={buttonSx}
          >
            {isMd ? "Facebook" : null}
          </Button>
        </Box>
        <Box marginRight={{ xs: 0, md: 2 }}>
          <Button
            variant="text"
            size="large"
            component="a"
            target="blank"
            href="https://instagram.com/apogeom"
            startIcon={<InstagramIcon />}
            sx={buttonSx}
          >
            {isMd ? "Instagram" : null}
          </Button>
        </Box>
      </Box>
      <Box mb={{ xs: 2, md: 4 }}>
        <Typography variant="h6" color="text.secondary">
          Or submit the form below.
        </Typography>
      </Box>
      <Box>
        <Form />
      </Box>
    </Box>
  )
}

export default Contact
