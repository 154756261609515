import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { ThemeProvider } from "@mui/material/styles"
import Paper from "@mui/material/Paper"
import CssBaseline from "@mui/material/CssBaseline"
import useMediaQuery from "@mui/material/useMediaQuery"
import AOS from "aos"
import getTheme from "../theme"

export const useDarkMode = () => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)")
  const defaultMode = prefersDarkMode ? "dark" : "light"

  const [themeMode, setTheme] = useState(defaultMode)
  const [mountedComponent, setMountedComponent] = useState(false)

  const setMode = mode => {
    try {
      window.localStorage.setItem("themeMode", mode)
    } catch {
      /* do nothing */
    }

    setTheme(mode)
  }

  const themeToggler = () => {
    themeMode === "light" ? setMode("dark") : setMode("light")
  }

  useEffect(() => {
    try {
      const localTheme = window.localStorage.getItem("themeMode")
      localTheme ? setTheme(localTheme) : setMode(defaultMode)
    } catch {
      setMode(defaultMode)
    }

    setMountedComponent(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (defaultMode !== themeMode) setMode(defaultMode)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultMode])

  return [themeMode, themeToggler, mountedComponent, defaultMode]
}

const Page = ({ children }) => {
  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side")
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles)
    }

    AOS.init({
      once: true,
      delay: 0,
      duration: 800,
      offset: 0,
      easing: "ease-in-out"
    })
  }, [])

  const [themeMode, themeToggler, mountedComponent] = useDarkMode()

  useEffect(() => {
    AOS.refresh()
  }, [mountedComponent, themeMode])

  return (
    <ThemeProvider theme={getTheme(themeMode, themeToggler)}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <Paper elevation={0}>{children}</Paper>
    </ThemeProvider>
  )
}

Page.propTypes = {
  children: PropTypes.node.isRequired
}

export default Page
