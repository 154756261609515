import React from "react"

// eslint-disable-next-line no-unused-vars
import { Home, Blog, BlogArticle, Faq, Portfolio, Terms, NotFound } from "."

const routes = [
  {
    path: "/",
    renderer: (params = {}) => <Home {...params} />
  },
  // {
  //   path: "/portfolio",
  //   renderer: (params = {}) => <Portfolio {...params} />
  // },
  {
    path: "/404",
    renderer: (params = {}) => <NotFound {...params} />
  }
]

export default routes
