import React from "react"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import ListItem from "@mui/material/ListItem"
import ListItemAvatar from "@mui/material/ListItemAvatar"
import ListItemText from "@mui/material/ListItemText"
import Avatar from "@mui/material/Avatar"
import MessageIcon from "@mui/icons-material/MessageOutlined"
import CheckIcon from "@mui/icons-material/Check"

import { scrollTo } from "../../../../helpers"

const Integration = () => (
  <Box>
    <Box marginBottom={2}>
      <Typography
        sx={{
          textTransform: "uppercase",
          fontWeight: "medium"
        }}
        gutterBottom
        color="primary"
        align="center"
      >
        Business integration
      </Typography>
      <Typography variant="h4" sx={{ fontWeight: 700 }} align="center">
        Our team loves working with your favourite tools.
      </Typography>
      <Typography variant="h6" component="p" color="text.secondary" align="center">
        We will adapt to all your business needs.
      </Typography>
    </Box>
    <Box marginBottom={4} width={1} display="flex" justifyContent="center">
      <Box
        paddingBottom={{ xs: 1, md: 0 }}
        display="flex"
        flexWrap="wrap"
        alignItems="flex-start"
        flexDirection={{ xs: "column", lg: "row" }}
      >
        {[
          "Source control and codebase",
          "Project management tools",
          "Communication channels",
          "Standards and processes",
          "Team schedule"
        ].map((item, i) => (
          <Box
            key={i}
            display="flex"
            alignItems="center"
            flexDirection="column"
            flex="0 0 auto"
            marginX={2}
          >
            <Box component={ListItem} disableGutters width="auto" padding={0}>
              <Box component={ListItemAvatar} minWidth="auto !important" marginRight={2}>
                <Box component={Avatar} bgcolor="primary.main" width={20} height={20}>
                  <CheckIcon fontSize="small" />
                </Box>
              </Box>
              <ListItemText primary={item} />
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
    <Box marginTop={2} display="flex" justifyContent="center">
      <Button
        color="primary"
        variant="contained"
        size="large"
        endIcon={<MessageIcon />}
        onClick={() => scrollTo("contact")}
      >
        Get started
      </Button>
    </Box>
  </Box>
)

export default Integration
