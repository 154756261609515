import React from "react"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import StarIcon from "@mui/icons-material/Star"
import MessageIcon from "@mui/icons-material/MessageOutlined"
import { useTheme } from "@mui/material/styles"
import { colors } from "@mui/material"

import { scrollTo } from "../../../../helpers"

const Testimonials = () => {
  const theme = useTheme()

  return (
    <Box>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography
          sx={{
            textTransform: "uppercase",
            fontWeight: "medium"
          }}
          gutterBottom
          color={colors.red[theme.palette.mode === "dark" ? 400 : 500]}
          align="center"
        >
          Testimonials
        </Typography>
        <Typography variant="h4" sx={{ fontWeight: 700 }} align="center">
          Our customers love us.
        </Typography>
        <Typography variant="h6" component="p" color="text.secondary" align="center">
          And we love our customers back.
        </Typography>
        <Box marginTop={{ xs: 2, sm: 4 }}>
          <Typography variant="h6" component="p" align="center">
            With great technical knowledge, professionalism and positive, friendly attitude, Apogeom
            Software can make your software project succeed.
          </Typography>
          <Box display="flex" justifyContent="center" marginTop={{ xs: 1, sm: 2 }}>
            {[1, 2, 3, 4, 5].map(item => (
              <Box key={item} color="secondary.main">
                <StarIcon />
              </Box>
            ))}
          </Box>
          <Typography
            variant="h6"
            sx={{ fontWeight: 700 }}
            align="center"
            marginTop={{ xs: 1, sm: 2 }}
          >
            The Ardonagh Group
          </Typography>
          {/* <Typography color="text.secondary" align="center">
            The Ardonagh Group
          </Typography> */}
        </Box>
      </Box>
      <Box marginTop={4} display="flex" justifyContent="center">
        <Button
          color="primary"
          variant="contained"
          size="large"
          endIcon={<MessageIcon />}
          onClick={() => scrollTo("contact")}
        >
          Become our customer
        </Button>
      </Box>
    </Box>
  )
}
export default Testimonials
