import React from "react"
import PropTypes from "prop-types"
import Drawer from "@mui/material/Drawer"
import Box from "@mui/material/Box"
import SidebarNav from "./components"

const Sidebar = ({ pages, isOpen, variant, onClose }) => (
  <Drawer
    anchor="left"
    onClose={() => onClose()}
    open={isOpen}
    variant={variant}
    sx={{
      "& .MuiPaper-root": {
        width: "100%",
        maxWidth: 280
      }
    }}
  >
    <Box
      sx={{
        height: "100%",
        padding: 1
      }}
    >
      <SidebarNav pages={pages} />
    </Box>
  </Drawer>
)

Sidebar.propTypes = {
  onClose: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  pages: PropTypes.object.isRequired
}

export default Sidebar
