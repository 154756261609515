import React from "react"
import { useTheme } from "@mui/material/styles"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import ListItem from "@mui/material/ListItem"
import ListItemAvatar from "@mui/material/ListItemAvatar"
import ListItemText from "@mui/material/ListItemText"
import Avatar from "@mui/material/Avatar"
import Button from "@mui/material/Button"
import MessageIcon from "@mui/icons-material/MessageOutlined"
import CheckIcon from "@mui/icons-material/Check"

import { scrollTo } from "../../../../../../helpers"

const FeatureList = () => {
  const theme = useTheme()

  return (
    <Box>
      <Box marginBottom={2}>
        <Typography
          sx={{
            textTransform: "uppercase",
            fontWeight: "medium"
          }}
          gutterBottom
          color="primary"
        >
          About us and our work
        </Typography>
      </Box>
      <Box marginBottom={2}>
        <Typography variant="h6" sx={{ fontWeight: 700 }} gutterBottom>
          We are a software consultancy and development company.
        </Typography>
        <Typography color="text.secondary" variant="subtitle1">
          We specialise in modern Web-based technologies software, however, we do not limit
          ourselves only to this. We always aim to deliver up to industry-wide standards services,
          such as Object-oriented programming (OOP), Test-driven development (TDD) and
          Service-oriented architecture (SOA) whilst adhering to various design principles, such as
          SOLID, KISS or DRY, and producing pristine technical documentation.
          <br /> <br />
          We possess {new Date().getFullYear() - 2014}+ years commercial experience in designing,
          developing and documenting different types of software such as Web, Mobile or Desktop
          applications for a wide range of sizeable industries such as pharma, defence, betting,
          insurance, live events, automotive, tech, finance and many more.
          <br /> <br />
          Our development processes pay particular attention towards:
        </Typography>
      </Box>
      <Grid container spacing={1}>
        {[
          "Meticulous system analysis and design",
          "Accessibility and readability",
          "User Experience and User Interface (UX / UI)",
          "Search Engine Optimisation (SEO)",
          "Reusability and scalability",
          "Integrability and extensibility",
          "Maintenance and support"
        ].map((item, i) => (
          <Grid item xs={12} key={i}>
            <Box component={ListItem} disableGutters width="auto" padding={0}>
              <Box component={ListItemAvatar} minWidth="auto !important" marginRight={2}>
                <Box component={Avatar} bgcolor={theme.palette.primary.main} width={20} height={20}>
                  <CheckIcon fontSize="small" />
                </Box>
              </Box>
              <ListItemText primary={item} />
            </Box>
          </Grid>
        ))}
      </Grid>
      <Box marginTop={4}>
        <Button
          variant="contained"
          size="large"
          endIcon={<MessageIcon />}
          onClick={() => scrollTo("process")}
        >
          See our process
        </Button>
      </Box>
    </Box>
  )
}

export default FeatureList
