import React from "react"
import Grid from "@mui/material/Grid"

import Container from "../../../../components/Container"
import { FeatureList, MasonryCards } from "./components"

const About = () => (
  <Container>
    <Grid container spacing={4} flexDirection={{ xs: "column-reverse", md: "row" }}>
      <Grid item xs={12} md={6}>
        <MasonryCards />
      </Grid>
      <Grid item container alignItems="center" xs={12} md={6}>
        <FeatureList />
      </Grid>
    </Grid>
  </Container>
)

export default About
