import React, { useRef } from "react"
import { useTheme } from "@mui/material/styles"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import useMediaQuery from "@mui/material/useMediaQuery"
import Button from "@mui/material/Button"
import MessageIcon from "@mui/icons-material/MessageOutlined"

import { scrollTo } from "../../../../helpers"

const clients = [
  "./assets/images/clients/ardonagh.png",
  "./assets/images/clients/astrazeneca.png",
  "./assets/images/clients/baesystems.png",
  "./assets/images/clients/bbc.png",
  "./assets/images/clients/daiichi.png",
  "./assets/images/clients/dyson.png",
  "./assets/images/clients/experian.png",
  "./assets/images/clients/flooid.png",
  "./assets/images/clients/gsk.png",
  "./assets/images/clients/invertek.png",
  "./assets/images/clients/johnshoncontrols.png",
  "./assets/images/clients/jpc.png",
  "./assets/images/clients/kemet.png",
  "./assets/images/clients/l3.png",
  "./assets/images/clients/merck.png",
  "./assets/images/clients/renault.png",
  "./assets/images/clients/roche.png",
  "./assets/images/clients/rollsroyce.png",
  "./assets/images/clients/sanofi.png",
  "./assets/images/clients/shell.png",
  "./assets/images/clients/smithnephew.png",
  "./assets/images/clients/trustly.png",
  "./assets/images/clients/visa.png"
]

const Clients = () => {
  const ref = useRef(null)
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.up("xs"), {
    defaultMatches: true
  })
  const isSm = useMediaQuery(theme.breakpoints.up("sm"), {
    defaultMatches: true
  })
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true
  })
  const isLg = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true
  })

  let slidesToShow = 2
  if (isXs) {
    slidesToShow = 2
  }
  if (isSm) {
    slidesToShow = 3
  }
  if (isMd) {
    slidesToShow = 4
  }
  if (isLg) {
    slidesToShow = 5
  }

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: "uppercase",
            fontWeight: "medium"
          }}
          gutterBottom
          color="secondary"
          align="center"
        >
          Our customers
        </Typography>
        <Typography variant="h4" sx={{ fontWeight: 700 }} align="center">
          You are in good company.
        </Typography>
        <Typography variant="h6" component="p" color="text.secondary" align="center">
          We worked on projects for.
        </Typography>
      </Box>
      <Box>
        <swiper-container
          ref={ref}
          slides-per-view={slidesToShow}
          autoplay="true"
          speed="500"
          loop="true"
          navigation={false}
          pagination={false}
          space-between="50"
          centered-slides="true"
        >
          {clients.map((item, i) => (
            <swiper-slide key={i}>
              <Box
                key={i}
                index={i}
                sx={{
                  maxWidth: `${100 / slidesToShow}vw`,
                  height: { xs: 40, md: 60 },
                  backgroundImage: `url(${item})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  filter:
                    theme.palette.mode === "dark"
                      ? "brightness(0) invert(1)"
                      : "invert(12%) sepia(8%) saturate(4364%) hue-rotate(187deg) brightness(95%) contrast(86%)"
                }}
              />
            </swiper-slide>
          ))}
        </swiper-container>
      </Box>
      <Box marginTop={4} maxWidth={1} display="flex" flexDirection="column" alignItems="center">
        <Button
          variant="contained"
          size="large"
          endIcon={<MessageIcon />}
          onClick={() => scrollTo("contact")}
        >
          Join the list
        </Button>
      </Box>
    </Box>
  )
}

export default Clients
