import React from "react"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import Avatar from "@mui/material/Avatar"
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import ConstructionIcon from "@mui/icons-material/Construction"
import WebIcon from "@mui/icons-material/Web"
import SmartphoneIcon from "@mui/icons-material/Smartphone"
import DesktopIcon from "@mui/icons-material/DesktopWindowsOutlined"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import { alpha, useTheme } from "@mui/material/styles"
import { colors } from "@mui/material"

import { scrollTo } from "../../../../../../helpers"

const MasonryCards = () => {
  const theme = useTheme()

  const services = [
    {
      id: "arch",
      color: theme.palette.secondary.light,
      title: "Architecture",
      subtitle:
        "Determining and documenting appropriate up-to-date methodologies and tools for new or existing software projects.",
      icon: <ConstructionIcon fontSize="large" />
    },
    {
      id: "web",
      color: theme.palette.primary.light,
      title: "Web applications",
      subtitle:
        "Developing mobile-first Web applications using various languages and frameworks, whilst keeping up with the latest trends.",
      icon: <WebIcon fontSize="large" />
    },
    {
      id: "mobile",
      color: colors.red[theme.palette.mode === "dark" ? 400 : 500],
      title: "Mobile applications",
      subtitle:
        "Building cross-platform Mobile applications and publishing to different app stores.",
      icon: <SmartphoneIcon fontSize="large" />
    },
    {
      id: "desktop",
      color: colors.green[theme.palette.mode === "dark" ? 400 : 500],
      title: "Desktop applications",
      subtitle:
        "Building cross-platform Desktop applications and publishing to different app stores.",
      icon: <DesktopIcon fontSize="large" />
    }
  ]

  return (
    <Grid container spacing={4}>
      {services.map((item, index) => (
        <Grid item xs={12} sm={6} key={index}>
          <Box
            display="block"
            width={1}
            sx={{
              transform: index % 2 === 1 ? { md: "translateY(80px)" } : "none"
            }}
          >
            <Box component={Card} padding={4} width={1}>
              <Box display="flex" flexDirection="column">
                <Box
                  component={Avatar}
                  width={60}
                  height={60}
                  marginBottom={2}
                  bgcolor={alpha(item.color, 0.1)}
                  color={item.color}
                  variant="rounded"
                  borderRadius={2}
                >
                  {item.icon}
                </Box>
                <Typography variant="h6" gutterBottom sx={{ fontWeight: 700 }}>
                  {item.title}
                </Typography>
                <Typography color="text.secondary">{item.subtitle}</Typography>
                <Box sx={{ flexGrow: 1 }} />
                <Box marginTop={2} display="flex" justifyContent="flex-end">
                  <Button endIcon={<ChevronRightIcon />} onClick={() => scrollTo(item.id)}>
                    Learn more
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  )
}

export default MasonryCards
