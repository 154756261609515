import React from "react"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import PhoneIcon from "@mui/icons-material/Phone"
import EmailIcon from "@mui/icons-material/Email"
import LinkedInIcon from "@mui/icons-material/LinkedIn"
import FacebookIcon from "@mui/icons-material/Facebook"
import InstagramIcon from "@mui/icons-material/Instagram"
import { useTheme } from "@mui/material/styles"

import { getLogo } from "../../../../helpers"

const Footer = () => {
  const theme = useTheme()
  const { mode } = theme.palette

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width={1}
          flexDirection={{ xs: "column", md: "row" }}
        >
          <Box display="flex" component="a" href="/" title="Apogeom" width={{ xs: 120, md: 150 }}>
            <Box component="img" src={getLogo(mode === "light")} height={1} width={1} />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            marginTop={{ xs: 2, md: 0 }}
            flexDirection={{ xs: "column", md: "row" }}
          >
            <Box marginRight={{ xs: 0, md: 2 }}>
              <Button
                variant="text"
                component="a"
                target="_blank"
                href="tel:+447715344056"
                startIcon={<PhoneIcon />}
                sx={{
                  color: "text.primary",
                  transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;",
                  ":hover": {
                    bgcolor: `${theme.palette.text.primary}09`
                  }
                }}
              >
                +44 (0) 7715 344 056
              </Button>
            </Box>
            <Box marginRight={{ xs: 0, md: 2 }}>
              <Button
                variant="text"
                component="a"
                target="_blank"
                href="mailto:contact@apogeom.com"
                startIcon={<EmailIcon />}
                sx={{
                  color: "text.primary",
                  transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;",
                  ":hover": {
                    bgcolor: `${theme.palette.text.primary}09`
                  }
                }}
              >
                contact@apogeom.com
              </Button>
            </Box>
            <Box display="flex" flexWrap="wrap">
              <Box marginRight={{ xs: 0, md: 2 }}>
                <IconButton
                  aria-label="Linkedin"
                  color="text.secondary"
                  component="a"
                  target="blank"
                  href="https://uk.linkedin.com/company/apogeom"
                  sx={{
                    borderRadius: "4px"
                  }}
                >
                  <LinkedInIcon />
                </IconButton>
              </Box>
              <Box marginRight={{ xs: 0, md: 2 }}>
                <IconButton
                  aria-label="Facebook"
                  color="text.secondary"
                  component="a"
                  target="blank"
                  href="https://facebook.com/apogeom"
                  sx={{
                    borderRadius: "4px"
                  }}
                >
                  <FacebookIcon />
                </IconButton>
              </Box>
              <Box>
                <IconButton
                  aria-label="Instagram"
                  color="text.secondary"
                  component="a"
                  target="blank"
                  href="https://instagram.com/apogeom"
                  sx={{
                    borderRadius: "4px"
                  }}
                >
                  <InstagramIcon />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography align="center" variant="subtitle2" color="text.primary" gutterBottom>
          &copy; {new Date().getFullYear()} Apogeom Ltd. All rights reserved.
        </Typography>
        <Typography align="center" variant="caption" color="text.secondary" component="p">
          71-75 Shelton Street, Covent Garden, London, United Kingdom, WC2H 9JQ <br />
          Company No: 14648928 <br />
          VAT No: 439336770
        </Typography>
      </Grid>
    </Grid>
  )
}

export default Footer
