import React from "react"
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import { HelmetProvider, Helmet } from "react-helmet-async"
import Page from "./components/Page"
import routes from "./views/routes"

import "aos/dist/aos.css"

const App = () => (
  <HelmetProvider>
    <Page>
      <Helmet
        defaultTitle="Apogeom Software"
        titleTemplate="%s | Apogeom Sofware Consultancy and Development Services"
      />
      <BrowserRouter>
        <Routes>
          {routes.map((item, i) => (
            <Route key={i} path={item.path} element={item.renderer()} />
          ))}
          <Route path="*" element={<Navigate replace to="/404" />} />
        </Routes>
      </BrowserRouter>
    </Page>
  </HelmetProvider>
)

export default App
