import React from "react"
import IconButton from "@mui/material/IconButton"
import LightModeIcon from "@mui/icons-material/LightModeOutlined"
import DarkModeIcon from "@mui/icons-material/DarkModeOutlined"
import { useTheme } from "@mui/material/styles"

const ThemeModeToggler = () => {
  const theme = useTheme()
  const { themeToggler } = theme
  const { mode } = theme.palette

  return (
    <IconButton
      onClick={() => themeToggler()}
      aria-label={`Turn ${mode === "light" ? "dark" : "light"} mode on`}
      color={mode === "light" ? "alternate.dark" : "secondary"}
      sx={{
        borderRadius: "4px"
      }}
    >
      {mode === "light" ? <DarkModeIcon /> : <LightModeIcon />}
    </IconButton>
  )
}

export default ThemeModeToggler
