import React from "react"
import { Helmet } from "react-helmet-async"
import Box from "@mui/material/Box"
import { useTheme } from "@mui/material/styles"
import { colors } from "@mui/material"
import { Main } from "../../layouts"
import Container from "../../components/Container"
import {
  Hero,
  About,
  Architecture,
  Clients,
  Web,
  Integration,
  Mobile,
  Testimonials,
  Desktop,
  Process,
  Contact
} from "./components"

const Home = () => {
  const theme = useTheme()

  return (
    <>
      <Helmet>
        <title>Home</title>
      </Helmet>
      <Main>
        <Hero />
        <Box id="about" position="relative">
          <Container>
            <About />
          </Container>
        </Box>
        <Box id="arch" bgcolor="secondary.light">
          <Container>
            <Architecture />
          </Container>
        </Box>
        <Container id="clients" maxWidth={1}>
          <Clients />
        </Container>
        <Box id="web" bgcolor="primary.light">
          <Container>
            <Web />
          </Container>
        </Box>
        <Container id="integration" maxWidth={1}>
          <Integration />
        </Container>
        <Box id="mobile" bgcolor={colors.red[theme.palette.mode === "dark" ? 400 : 500]}>
          <Container>
            <Mobile />
          </Container>
        </Box>
        <Container id="testimonials" maxWidth={1}>
          <Testimonials />
        </Container>
        <Box id="desktop" bgcolor={colors.green[theme.palette.mode === "dark" ? 400 : 500]}>
          <Container>
            <Desktop />
          </Container>
        </Box>
        <Container id="process">
          <Process />
        </Container>
        <Box
          id="contact"
          position="relative"
          sx={{
            backgroundColor: theme.palette.alternate.main
          }}
        >
          <Container>
            <Contact />
          </Container>
        </Box>
      </Main>
    </>
  )
}

export default Home
