import React from "react"
import PropTypes from "prop-types"
import { useLocation, Link as RouterLink } from "react-router-dom"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import { alpha, useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import MenuIcon from "@mui/icons-material/Menu"
import MessageIcon from "@mui/icons-material/MessageOutlined"
// import { NavItem } from "./components"
import ThemeModeToggler from "../../../../components/ThemeModeToggler"

import { scrollTo, getLogo } from "../../../../helpers"

const Topbar = ({ onSidebarOpen, pages = {}, shouldColourInvert = false }) => {
  const { pathname } = useLocation()

  const theme = useTheme()
  const { mode } = theme.palette

  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true
  })

  const ButtonComponent = isMd ? Button : IconButton
  const buttonProps = isMd ? { variant: "contained", endIcon: <MessageIcon /> } : {}
  const linkProps =
    pathname === "/"
      ? { onClick: () => scrollTo("contact") }
      : { component: RouterLink, to: "/", state: { scrollTo: "contact", behavior: "instant" } }

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" width={1}>
      <Box display="flex" component="a" href="/" title="Apogeom" width={{ xs: 120, md: 150 }}>
        <Box
          component="img"
          src={getLogo(mode === "light" && !shouldColourInvert, false)}
          height={1}
          width={1}
        />
      </Box>
      <Box sx={{ display: { xs: "none", md: "flex" } }} alignItems="center">
        {/* <Box>
          <NavItem
            title={'Landings'}
            id={'landing-pages'}
            items={landingPages}
            shouldColourInvert={shouldColourInvert}
          /> */}
      </Box>
      <Box display="flex" alignItems="center">
        <Box marginLeft={isMd ? 4 : 1}>
          <ThemeModeToggler />
        </Box>
        <Box marginLeft={isMd ? 4 : 1}>
          <ButtonComponent color="primary" size="large" {...buttonProps} {...linkProps}>
            {isMd ? "Contact us" : <MessageIcon />}
          </ButtonComponent>
        </Box>
      </Box>
      {Object.keys(pages).length > 0 && (
        <Box sx={{ display: { xs: "block", md: "none" } }} alignItems="center">
          <Button
            onClick={() => onSidebarOpen()}
            aria-label="Menu"
            variant="text"
            sx={{
              minWidth: "auto",
              padding: 1,
              borderColor: alpha(theme.palette.divider, 0.2)
            }}
          >
            <MenuIcon />
          </Button>
        </Box>
      )}
    </Box>
  )
}

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  pages: PropTypes.object,
  shouldColourInvert: PropTypes.bool
}

export default Topbar
