import React, { useState, forwardRef, createRef } from "react"
import { useFormik } from "formik"
import * as yup from "yup"
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Grid from "@mui/material/Grid"
import Snackbar from "@mui/material/Snackbar"
import MuiAlert from "@mui/material/Alert"
import FormHelperText from "@mui/material/FormHelperText"
import ReCAPTCHA from "react-google-recaptcha"
import { useTheme } from "@mui/material/styles"

// eslint-disable-next-line react/display-name
const Alert = forwardRef((props, ref) => (
  <MuiAlert elevation={1} ref={ref} variant="filled" {...props} />
))

const validationSchema = yup.object({
  firstName: yup
    .string()
    .trim()
    .min(2, "Please enter a valid first name.")
    .max(50, "Please enter a valid first name.")
    .required("Please specify your first name."),
  lastName: yup
    .string()
    .trim()
    .min(2, "Please enter a valid last name.")
    .max(50, "Please enter a valid last name.")
    .required("Please specify your last name."),
  email: yup
    .string()
    .trim()
    .email("Please enter a valid email address.")
    .required("Please specify your email."),
  subject: yup.string().trim().required("Please specify a subject."),
  message: yup.string().trim().required("Please submit some brief project details."),
  recaptcha: yup.string().required("Please complete the reCAPTCHA challenge.")
})

const Form = () => {
  const recaptchaRef = createRef()

  const [{ open, type, message }, setSnack] = useState({
    open: false,
    type: null,
    message: null
  })

  const {
    palette: { mode }
  } = useTheme()

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    subject: "",
    message: "",
    recaptcha: null
  }

  const onSubmit = async (values, { resetForm }) => {
    try {
      const { ok } = await fetch("/contact", {
        method: "post",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(values)
      })

      recaptchaRef.current.reset()
      formik.setFieldValue("recaptcha", null)

      if (ok) {
        resetForm()
        setSnack({
          open: true,
          type: "success",
          message: "Your information was submitted successfully!"
        })
      } else {
        setSnack({
          open: true,
          type: "error",
          message: "There was an error submitting your information. Please try again!"
        })
      }
    } catch (error) {
      setSnack({
        open: true,
        type: "error",
        message: "There was an error submitting your information. Please try again!"
      })
    }
  }

  const handleClose = (e, reason) => {
    if (reason === "clickaway") {
      return
    }

    setSnack({ open: false, type: null, message: null })
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })

  return (
    <>
      {open && (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={handleClose} severity={type} sx={{ width: 1 }}>
            {message}
          </Alert>
        </Snackbar>
      )}
      <Box>
        <Box padding={{ xs: 3, sm: 6 }} width={1} component={Card} boxShadow={1} marginBottom={4}>
          <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={{ height: 54 }}
                  label="First name"
                  variant="outlined"
                  color="primary"
                  size="medium"
                  name="firstName"
                  autoComplete="given-name"
                  fullWidth
                  required
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                  helperText={formik.touched.firstName && formik.errors.firstName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={{ height: 54 }}
                  label="Last name"
                  variant="outlined"
                  color="primary"
                  size="medium"
                  name="lastName"
                  autoComplete="family-name"
                  fullWidth
                  required
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                  helperText={formik.touched.lastName && formik.errors.lastName}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{ height: 54 }}
                  label="Email"
                  type="email"
                  variant="outlined"
                  color="primary"
                  size="medium"
                  name="email"
                  autoComplete="email"
                  fullWidth
                  required
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{ height: 54 }}
                  label="Subject"
                  variant="outlined"
                  color="primary"
                  size="medium"
                  name="subject"
                  autoComplete="off"
                  fullWidth
                  required
                  value={formik.values.subject}
                  onChange={formik.handleChange}
                  error={formik.touched.subject && Boolean(formik.errors.subject)}
                  helperText={formik.touched.subject && formik.errors.subject}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Project details"
                  multiline
                  minRows={2}
                  maxRows={10}
                  variant="outlined"
                  color="primary"
                  size="medium"
                  name="message"
                  autoComplete="off"
                  fullWidth
                  required
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  error={formik.touched.message && Boolean(formik.errors.message)}
                  helperText={formik.touched.message && formik.errors.message}
                />
              </Grid>
              <Grid item container xs={12} direction="column">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey="6LdAExsnAAAAADLHOHBpNFxUt8ZQpIe9b57IyDCV"
                  theme={mode}
                  onChange={token => formik.setFieldValue("recaptcha", token)}
                  onExpired={() => {
                    formik.setFieldValue("recaptcha", null)
                    setSnack({
                      open: true,
                      type: "warning",
                      message:
                        "Your reCAPTCHA challenge has expired. Please recomplete it in order to submit!"
                    })
                  }}
                  onErrored={() => {
                    formik.setFieldValue("recaptcha", null)
                    setSnack({
                      open: true,
                      type: "error",
                      message:
                        "There was an error completing the reCAPTCHA challenge. Please try again!"
                    })
                  }}
                />
                <FormHelperText error sx={{ marginLeft: "14px" }}>
                  {formik.errors.recaptcha || " "}
                </FormHelperText>
              </Grid>
              <Grid item container justifyContent="center" xs={12}>
                <Button
                  sx={{ height: 54, minWidth: 150 }}
                  variant="contained"
                  color="primary"
                  size="medium"
                  type="submit"
                  fullWidth
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </>
  )
}

export default Form
