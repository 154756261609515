export const logoFullLightSrc = "./assets/images/logo/full-light.svg"
export const logoFullDarkSrc = "./assets/images/logo/full-dark.svg"
export const logoShortLightSrc = "./assets/images/logo/short-light.svg"
export const logoShortDarkSrc = "./assets/images/logo/short-dark.svg"

export const scrollTo = (id, behavior = "smooth", callback = null) => {
  if (callback && typeof callback === "function") {
    callback()
  }

  const behaviour = behavior || "smooth"

  setTimeout(() => {
    const idString = `#${id}`

    const element = document.querySelector(idString)
    if (!element) {
      return
    }

    window.scrollTo({ left: 0, top: element.offsetTop, behavior: behaviour })
  })
}

export const getLogo = (darkModeOn = false, mobile = false) => {
  if (mobile) {
    if (!darkModeOn) return logoShortLightSrc
    return logoShortDarkSrc
  }

  if (!darkModeOn) return logoFullLightSrc
  return logoFullDarkSrc
}
