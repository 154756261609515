import React from "react"
import { Helmet } from "react-helmet-async"
import { useTheme } from "@mui/material/styles"
import { Link as RouterLink } from "react-router-dom"
import Link from "@mui/material/Link"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import useMediaQuery from "@mui/material/useMediaQuery"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"

import Main from "../../layouts/Main"
import Container from "../../components/Container"

const NotFound = () => {
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true
  })

  return (
    <>
      <Helmet>
        <title>404 Not Found</title>
      </Helmet>
      <Main>
        <Box
          bgcolor={theme.palette.alternate.main}
          position="relative"
          minHeight="calc(100vh - 247px)"
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={1}
          marginTop={-12}
          paddingTop={12}
        >
          <Container>
            <Grid container>
              <Grid item container alignItems="center" justifyContent="center" xs={12} md={6}>
                <Box>
                  <Typography
                    variant="h1"
                    component="h1"
                    align={isMd ? "left" : "center"}
                    sx={{ fontWeight: 700 }}
                  >
                    404
                  </Typography>
                  <Typography
                    variant="h6"
                    component="p"
                    color="text.secondary"
                    align={isMd ? "left" : "center"}
                  >
                    Oops! Looks like you followed a bad link.
                    <br />
                    If you think this is a problem with us, please{" "}
                    <Link
                      underline="none"
                      component={RouterLink}
                      to="/"
                      state={{ scrollTo: "contact", behavior: "instant" }}
                    >
                      tell us.
                    </Link>
                  </Typography>
                  <Box
                    marginTop={4}
                    display="flex"
                    justifyContent={{ xs: "center", md: "flex-start" }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      startIcon={<ChevronLeftIcon />}
                      // component={RouterLink}
                      to="/"
                    >
                      Back home
                    </Button>
                  </Box>
                </Box>
              </Grid>
              <Grid item container justifyContent="center" xs={12} md={6}>
                <Box height={1} width={1} maxWidth={500}>
                  <Box
                    component="img"
                    src="./assets/images/404.svg"
                    width={1}
                    height={1}
                    // sx={{
                    //   filter: theme.palette.mode === "dark" ? "brightness(0.8)" : "none"
                    // }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Main>
    </>
  )
}

export default NotFound
