import React from "react"
import { alpha, useTheme } from "@mui/material/styles"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Avatar from "@mui/material/Avatar"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import MessageIcon from "@mui/icons-material/MessageOutlined"
import MailIcon from "@mui/icons-material/MailOutline"
import CalendarIcon from "@mui/icons-material/InsertInvitationOutlined"
import TrendingUpIcon from "@mui/icons-material/TrendingUp"
import { colors } from "@mui/material"

import { scrollTo } from "../../../../helpers"

const process = [
  {
    title: "Contact us",
    subtitle:
      "Get in touch by email, phone, filling in our contact form or through our Social Media channels.",
    icon: <MailIcon fontSize="large" />
  },
  {
    title: "Schedule a meeting",
    subtitle:
      "We aim to swiftly reply to your enquiry and book a meeting as quickly as convienient for you, to get your project going.",
    icon: <CalendarIcon fontSize="large" />
  },
  {
    title: "Ascend to success",
    subtitle:
      "Start working with us and turn your existing project or ideas into a great success. We don't just build great software, we also build great relationships.",
    icon: <TrendingUpIcon fontSize="large" />
  }
]

const Process = () => {
  const theme = useTheme()

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: "uppercase",
            fontWeight: "medium"
          }}
          gutterBottom
          color={colors.green[theme.palette.mode === "dark" ? 400 : 500]}
          align="center"
        >
          Our process
        </Typography>
        <Typography variant="h4" align="center" sx={{ fontWeight: 700 }}>
          How we work.
        </Typography>
        <Typography variant="h6" component="p" color="text.secondary" align="center">
          Our priority is to help you and your project quickly.
        </Typography>
      </Box>
      <Box marginBottom={4}>
        <Grid container spacing={4}>
          {process.map((item, i) => (
            <Grid item xs={12} md={4} key={i}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                data-aos="fade-up"
                data-aos-delay={i * 100}
                data-aos-offset={100}
                data-aos-duration={600}
              >
                <Box
                  component={Avatar}
                  width={80}
                  height={80}
                  marginBottom={2}
                  bgcolor={alpha(theme.palette.text.primary, 0.1)}
                  color="text.primary"
                  variant="rounded"
                  borderRadius={2}
                >
                  {item.icon}
                </Box>
                <Typography variant="h5" gutterBottom sx={{ fontWeight: 700 }}>
                  {item.title}
                </Typography>
                <Typography color="text.secondary" align="center">
                  {item.subtitle}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box display="flex" justifyContent="center">
        <Button
          color="primary"
          variant="contained"
          size="large"
          endIcon={<MessageIcon />}
          onClick={() => scrollTo("contact")}
        >
          Work with us
        </Button>
      </Box>
    </Box>
  )
}

export default Process
